
import gsap from '~/mixins/gsap';

import moment from 'moment'
export default {
    mixins : [gsap], 
    data(){
        return {
            alignTop: false,
            popup: null,
            timer: null,
            secondToKeepAlive: 10,
        }
    },
    computed:{
        popupOpen: {
            get(){
                return this.$store.state.layout.popupOpen
            },
            set(v){
                this.$store.commit('layout/setPopupOpen',v);
            }
        },
        blank(){
            if(this.popup && this.popup.ctaLink && this.popup.ctaLink.cached_url){
                var r = new RegExp('^(?:[a-z]+:)?//', 'i');
                return r.test(this.popup.ctaLink.cached_url);
            }
            return false;
        },
        assetType(){
            if(this.popup && this.popup.asset && this.popup.asset.filename){
                const img =  ['jpg','jpeg','gif','png'];
                const video =  ['mp4','mov','wmv'];
                var assetExtension = this.popup.asset.filename.split(/[#?]/)[0].split('.').pop().trim();
                if(img.includes(assetExtension)) return 'img';
                if(video.includes(assetExtension)) return 'video';
            }
            return null
        },
        path(){
            return this.$route.fullPath
        },
        wH () {
            return this.$store.state.scroll.wH;
        },
        wW () {
            return this.$store.state.scroll.wW;
        }
    },
    watch:{
        popup(){
            if(process.client){

                this.popupOpen = true;
            }
        },
        popupOpen(t){
            const mainBody = document.querySelector('#default')
            if(t){
                this.$nextTick(() => {
                    mainBody.style.overflow="hidden";
                    mainBody.style.maxHeight="100vh";
                    this.addListeners();
                    this.gsap.fromTo(this.$el,{opacity: 0},{opacity: 1});
                    if (this.popup.autoClose){
                        this.timer = setTimeout(()=> {this.popupOpen = false}, this.secondToKeepAlive* 1000)
                    }
                })
            }else{
                mainBody.style.overflow="auto";
                mainBody.style.maxHeight="unset";
                this.removeListeners();
                clearTimeout(this.timer)
                this.gsap.fromTo(this.$el,{opacity: 1},{opacity: 0});
                this.$cookies.set('hidePopup',true)
            }
        },
        path(){
            this.popupOpen = false;
        },
    },
    beforeDestroy() {
        if(this.timer )clearTimeout(this.timer);
    },
    mounted(){
        const hidePopup = this.$cookies.get('hidePopup');
        if(!hidePopup){
            this.$axios.get("storyblok/popups/").then((res)=> {
                if(res.data.stories.length > 0) {
                    this.popup = res.data.stories[0].content;
                    if(![null,undefined, 0, ""].includes(this.popup.secondToKeepAlive)) this.secondToKeepAlive = parseFloat(this.popup.secondToKeepAlive);
                }
            }).catch(err => {
                return false;
            })
        }
    },
    methods: {
        handleClose(e){
            if(e && e.target && e.target.classList.contains('popup')) this.popupOpen = false;
        },
        addListeners(){
            this.$el.addEventListener('click', this.handleClose)
        },
        removeListeners(){
            this.$el.removeEventListener('click', this.handleClose)
        },
        loaded(e){
            this.alignTop = e.clientHeight >= this.wH;
        },
    }
}
